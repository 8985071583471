import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Media from '@components/media/Media.tsx';
import More from '@components/table/components/More.tsx';
import tables from '@components/table/static/tables.tsx';
import getEndText from '@functions/getEndText.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getUserName from '@functions/getUserName.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import setFormatPhone from '@functions/setFormatPhone.ts';
import { UserT } from '@global/models/User.ts';
import { deleteUser } from '@requests/deleteUser.ts';

import { RenderColT } from '../../types.ts';

const renderClients: RenderColT = function (data) {
    const client = data.item as UserT;
    const name = data.name as (typeof tables)['clients']['colsOrder'][number];

    if (name === 'name') {
        const renderContent = () => (
            <>
                <div className="profileTable__colText">{getUserName({ user: client })}</div>
                {client.type === 'juristic' && (
                    <>
                        <Media current="mobile">, </Media>
                        <div className="profileTable__colText">
                            {client.company?.name}, ИНН {client.company?.inn}
                        </div>
                    </>
                )}
            </>
        );

        return (
            <>
                <Media current="desktop">
                    <div className="profileTable__holder _ROW">
                        <div className="profileTable__holderAvatar">
                            <Avatar
                                color={client.color}
                                firstSymb={client.secondName[0]}
                                secondSymb={client.firstName?.[0]}
                            />
                        </div>
                        <div className="profileTable__holderContent">{renderContent()}</div>
                    </div>
                </Media>
                <Media current="mobile">{renderContent()}</Media>
            </>
        );
    }

    if (name === 'status') {
        let statusClass = '_mediumColor';

        if (client.status === 'offline') {
            statusClass = '_waitColor';
        }

        if (client.status === 'online') {
            statusClass = '_successColor';
        }

        return (
            <>
                <div className={`profileTable__colText ${statusClass} _NOWRAP`}>
                    {client.statusText}
                </div>
            </>
        );
    }

    if (name === 'login') {
        return (
            <>
                <div className={`profileTable__colText _NOWRAP`}>
                    {client.type === 'physical' ? setFormatPhone(client.phone!) : client.email}
                </div>
            </>
        );
    }

    if (name === 'balance') {
        return (
            <>
                <div className={`profileTable__colText`}>
                    {getFormatPrice(client.balance)} ₽
                </div>
            </>
        );
    }

    if (name === 'orders') {
        const ordersCounter = client.ordersCounter || 0;

        return (
            <>
                <div className="profileTable__colText _NOWRAP">
                    {ordersCounter}
                    <Media current="mobile">
                        {' '}
                        {getEndText(ordersCounter, ['заказ', 'заказа', 'заказов'])}
                    </Media>
                </div>
                <More
                    name={`workers-${client._id}`}
                    actions={[
                        {
                            key: 'delete',
                            text: 'Удалить пользователя',
                            name: 'delete',
                            onClick: deleteUser.bind(null, {
                                user: client,
                                id: client._id,
                                successCallback: () => {
                                    document.dispatchEvent(
                                        new CustomEvent('refreshTable', {
                                            detail: { name: 'clients' },
                                        }),
                                    );
                                },
                            }),
                        },
                        {
                            key: 'logs',
                            name: 'logs',
                            onClick: async () => {
                                handlerPopup('logsPopup', {
                                    isShow: true,
                                    modelName: 'users',
                                    modelId: client._id,
                                });

                                return;
                            },
                        },
                    ]}
                />
            </>
        );
    }

    return null;
};

export default renderClients;
